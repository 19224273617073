/* ================================= Form Css Start =========================== */
/* input Start */
.common-input {
    border-radius: 5px;
    font-weight: 400;
    outline: none;
    width: 100%;
    padding: 17px 24px;
    background-color: transparent !important; 
    border: 1px solid var(--border-color);
    color: hsl(var(--black)); 
    line-height: 1;
    @include font-17; 
    @include md-screen {
        padding: 12px 24px;
    }
    @include sm-screen {
        padding: 10px 16px;
        @include font-15; 
    }
    &::placeholder {
        color: var(--gray-800);
        @include font-14; 
        transition: .25s linear;
        font-weight: 400;
    }
    &--md {
        padding: 13px 16px;
        @include font-15; 
    }
    &--sm {
        font-size: 14px;
        padding: 11px 16px;
        background-color: var(--gray-100) !important;
        @include sm-screen {
            padding: 7px 16px;
        }
        &::placeholder {
          font-size: 13px !important;
        }
    }
    &--lg {
        padding: 23px 24px;
        @include sm-screen {
            padding: 18px 24px;
        }
    } 
    &:focus {
        border-color: hsl(var(--main)); 
        box-shadow: none;
        &::placeholder {
            visibility: hidden;
            opacity: 0;
        }
    }
    &:disabled, &[readonly] {
        background-color: hsl(var(--black)/.2); 
        opacity: 1;
        border: 0;
    }
    &[type="password"] {
        color: hsl(var(--black)/.5);
        &:focus {
            color: hsl(var(--black)); 
        }
    }
    &[type="file"] {
        line-height: 50px;
        padding: 0;
        position: relative;
        &::file-selector-button {
            border: 1px solid hsl(var(--black) / .08);
            padding: 4px 6px;
            border-radius: .2em;
            background-color: hsl(var(--main)) !important;
            transition: .2s linear;
            line-height: 25px;
            position: relative;
            margin-left: 15px;
            color: hsl(var(--body-color)) !important;
            &:hover {
                background-color: hsl(var(--main));
                border: 1px solid hsl(var(--main));
                color: hsl(var(--black));
            }
        }
    }
}
.common-input {
    &--withIcon {
        padding-right: 50px !important;
    }
    &--withLeftIcon {
        padding-left: 50px !important;
    }
    &::placeholder {
        font-size: clampCal(15, 18) !important; 
    }
    &--light {
        border-color: hsl(var(--white)/.44); 
        color: hsl(var(--white));
        &::placeholder {
            color: hsl(var(--white)/.3); 
        }
    }
    &--grayBg {
        background-color: var(--gray-100) !important;
        border-color: var(--gray-100);
    }
}
/* input End */

/* input icon */
.input-icon {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    color: hsl(var(--heading-color));
    @include font-14; 
    &--left {
        left: 20px;
        right: auto;
    }
}
textarea+.input-icon {
    top: 15px;
    transform: translateY(0);
}
/* input icon */

/* Label */
.form-label {
    margin-bottom: 6px;
    @include font-14; 
    color: hsl(var(--heading-color));
    font-weight: 500;
    font-family: var(--poppins-font);
}

/* Form Select */  
.select-has-icon {
    position: relative;
    &::before {
        position: absolute;
        content: "\f107";
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        @include font-14; 
        @extend .text-gradient; 
        transition: 0.2s linear;
        pointer-events: none;
    }
    &.icon-black::before {
        -webkit-text-fill-color: hsl(var(--black)); 
    }
    select {
        background-image: none;
        -webkit-appearance: none;
        padding: 20px 24px;
        font-size: 18px;
        @include md-screen {
            padding: 15px 24px;
        }
        @include sm-screen {
            padding: 12px 16px;
        }
    }
    .common-input {
        padding-right: 30px !important;
    }
} 
.select {
    color: hsl(var(--black) / .6) !important;
    padding: 16px 24px;
    @include md-screen {
        padding: 11px 24px;
    }
    @include sm-screen {
        padding: 9px 24px;
        @include font-15; 
    }
    &:focus {
        border-color: hsl(var(--main));
        color: hsl(var(--black)) !important;
    }
    option {
        background-color: hsl(var(--white));
        color: hsl(var(--heading-color));
    }
}
/* Form Select End */

// Message Field Css
textarea {
    &.common-input {
        height: 140px;
        @include sm-screen {
            height: 110px;
        }
    }
}
/* Autofill Css */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, 
textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-text-fill-color: hsl(var(--heading-color)) !important;
    caret-color: hsl(var(--heading-color));
}
/* Autofill Css End */

/* Show Hide Password */
input#your-password, input#confirm-password {
    padding-right: 50px;
}
.password-show-hide {
    position: absolute;
    right: 20px;
    z-index: 5;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    color: hsl(var(--black)/.4);
}

/* Number Arrow None */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type=number]{
    -moz-appearance: textfield;
}

/* Custom Checkbox Design */
.common-check {
    @extend .flx-align; 
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0;
    }
    a {
        display: inline;
    }
    .form-check-input {
        transition: .2s linear;
        box-shadow: none;
        background-color: transparent;
        box-shadow: none !important;
        border: 0;
        position: relative;
        border-radius: 3px;
        width: 18px;
        height: 18px;
        border: 1px solid hsl(var(--black) / .4);
        cursor: pointer;
        transition: .2s linear;
        margin-top: 0;
        &:checked {
            background-color: hsl(var(--main)) !important;
            border-color: hsl(var(--main)) !important;
            box-shadow: none;
            &[type=checkbox] {
                background-image:none;
                }
            &::before {
                position: absolute;
                content: "\f00c";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                color: hsl(var(--white)); 
                @include font-11; 
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .form-check-label {
        font-weight: 500;
        width: calc(100% - 18px);
        padding-left: 12px;
        cursor: pointer;
        @include font-14; 
        color: hsl(var(--heading-color)); 
        font-family: var(--poppins-font)
    }
    label {
        @include xsm-screen {
            @include font-15; 
        }
    }
    a {
        @include xsm-screen {
            @include font-15; 
        }
    }
}
/* Custom Radio Design */
.common-radio {
    @extend .flx-align; 
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0;
    }
    .form-check-input {
        transition: .2s linear;
        box-shadow: none;
        border: 1px solid hsl(var(--black) / .4);
        position: relative;
        background-color: transparent;  
        cursor: pointer;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-top: 0;
        &:active {
            filter: brightness(100%);
        }
        &:checked {
            background-color: transparent;
            border-color: hsl(var(--main));
            &[type=radio] {
                background-image:none ;
            }
            &::before {
                position: absolute;
                content: "";
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                width: 10px;
                height: 10px;
                background-color: hsl(var(--main));
                border-radius: 50%;
                transition: .2s linear;
                z-index: 1;
            }
        } 
    }
    .form-check-label {
        font-weight: 500;
        width: calc(100% - 18px);
        padding-left: 12px;
        cursor: pointer;
        @include font-14; 
        color: hsl(var(--heading-color)); 
        font-family: var(--poppins-font)
    }
}
/* ================================= Form Css End =========================== */