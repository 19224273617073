/* ======================= Floating Chat Css Start ========================== */
.floating-chat {
    position: fixed;
    z-index: 99;
    bottom: 24px;
    left: 24px;
    &.active {
        .floating-chat {
            &__box {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                visibility: visible;
                opacity: 1;
            }
            &__icon i::before {
                content: '\f00d';
            }
        }
    }
    &__header {
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 16px;
      }
    &__box {
        clip-path: polygon(0 96%, 100% 96%, 100% 100%, 0% 100%);
        padding: 20px;
        position: absolute;
        left: 10px;
        bottom: 100%;
        transition: .2s linear;
        visibility: hidden;
        opacity: 0;
        &--inner {
            width: 250px;
            height: 400px;
            background: hsl(var(--white));
            box-shadow: var(--box-shadow);
            border-radius: 8px;
            padding: 12px;
            transition: .2s;
        }
      }
      &__img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
      &__item {
        display: flex;
        align-content: center;
        gap: 12px;
        margin-bottom: 12px;
        &.self {
            flex-direction: row-reverse;
          }
      }
      &__text {
        background-color: #f7f7f7;
        padding: 6px 10px;
        border-radius: 8px;
      }
      &__footer {
        display: flex;
        align-content: center;
        gap: 8px;
        padding: 8px 0;
      }
}

.floating-chat__list {
	max-height: 286px;
	overflow-y: auto;
    padding: 4px;
    
    &::-webkit-scrollbar {
        width: 6px;
        border-radius: 50px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f3f2f2;
        border-radius: 50px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #b9b8b8;
        border-radius: 50px;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.floating-chat {
    &__icon {
        background-color: hsl(var(--white));
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        color: hsl(var(--main));
        font-size: 20px;
        box-shadow: var(--box-shadow) !important;
      }
}

/* ======================= Floating Chat Css End ========================== */
