/* ================================ Property Feature Section Start ============================= */
.property-feature-item {
    background-color: hsl(var(--white));
    padding: 32px;
    text-align: center;
    border-radius: 8px;
    position: relative;
    transition: .2s linear;
    margin-top: 40px;
    &:hover {
        box-shadow: var(--box-shadow) !important;
        .property-feature-item {
            &__icon {
                img {
                    transform: rotate(360deg);
                }
            }
        }
    }
    &__icon {
        position: absolute;
        top: -40px;
        width: 80px;
        height: 80px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%);
        img {
            transition: .3s linear;
        }
    }
}
/* ================================ Property Feature Section End ============================= */