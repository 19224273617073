/* ======================= ChartBox Css Start ============================ */
.search-box {
  &--sm {
    .common-input {
      font-size: 14px;
      padding: 11px 16px;
      background-color: var(--gray-100) !important;
      @include sm-screen {
        padding: 7px 16px;
        }
      &::placeholder {
        font-size: 13px !important;
      }
    }
    .icon {
      font-size: 12px;
    }
  }
}

.chatbox-nav, .chatbox-chat, .chatbox-bottom, .chatbox-sidebar__top, .chatbox-sidebar__lists {
    padding: 16px;
}

.chatbox {
  background-color: hsl(var(--white));
  display: flex;
  align-items: start;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  height: 100vh;
  overflow: hidden;
  &-sidebar {
        width: 240px;
        border-right: 1px solid var(--border-color);
        flex-shrink: 0;
        height: 100%;
    }
  &-body {
        flex-grow: 1;
        height: 100%;
    }
}
.chatbox-sidebar {
    &__top {
        position: sticky;
        top: 0;
        background-color: hsl(var(--white));
        // &::before {
        //     position: absolute;
        //     content: "";
        //     width: calc(100% + 32px);
        //     height: calc(100% + 32px);
        //     background: #fff;
        //     top: -16px;
        //     left: -16px;
        //     @media (max-width: 479px) {
        //         position: absolute;
        //         content: "";
        //         width: calc(100% + 16px);
        //         height: calc(100% + 16px);
        //         background: #b39393;
        //         top: -8px;
        //         left: -8px;
        //         border-top-left-radius: 8px;
        //     }
        // }
    }
}


.chat-list {
  &__item {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__thumb {
    flex-shrink: 0;
    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }
  }
  &__content {
    flex-grow: 1;
  }
  &__name {
    font-size: 14px;
    margin-bottom: 0;
  }
  &__designation {
    font-size: 12px;
  }
}


.chatbox-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
    &__btn {
        color: hsl(var(--body-color));
      }
  }
  
.message-list {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    &_item {
        display: block;
        margin-bottom: 16px;
        &.reply {
            text-align: right;
            .text {
                background-color: hsl(var(--main));
                color: hsl(var(--white));
                border-radius: 50px 50px 3px 50px;
              }
          }
        .text {
            background-color: #f7f7f7;
            display: inline-block;
            padding: 6px 16px;
            border-radius: 50px 50px 50px 3px;
            color: hsl(var(--heading-color));
            font-size: 14px;
          }
          .time {
            display: block;
            color: hsl(var(--body-color));
            font-size: 12px;
            margin-top: 2px;
          }
      }
}


.chatbox-chat, .chatbox-sidebar {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
        border-radius: 50px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f3f2f2;
        border-radius: 50px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 50px;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
  }

.chatbox-chat {
    height: calc(100% - 154px);
  }

  .chatbox-bottom {
      border-top: 1px solid var(--border-color);
  }


  @media (max-width: 479px) {
        .chatbox-sidebar {
            width: 174px;
        }
        
        .chatbox-nav, .chatbox-chat, .chatbox-bottom, .chatbox-sidebar__top, .chatbox-sidebar__lists {
            padding: 8px;
        }
        
        .chat-list {
            &__thumb img {
                width: 36px;
                height: 36px;
            }
            &__item {
                gap: 8px;
            }
            &__name {
                font-size: 12px;
            }
            &__designation {
                font-size: 11px;
            }
        }
        .chatbox-chat {
            height: calc(100% - 141px);
        }
        .message-list {
            &_item {
                .text {
                    font-size: 12px;
                    padding: 6px 8px;
                }
            }
        }
        .chatbox-bottom {
            .btn.btn-main .text {
                display: none;
            }
        }
  }

/* ======================= ChartBox Css End ============================ */
