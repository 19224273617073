[dir=rtl] {
    text-align: right;


    /* ========================= Home One RTL Css Start ============================== */
    .mobile-menu {
        right: auto;
        left: 0;
    }
    
    .section-heading.style-left {
        text-align: right;
        margin-left: auto;
        margin-right: 0;
    }

    .offcanvas-btn {
        margin-left: clamp(1rem, -0.521rem + 3.17vw, 2.25rem);
    }
    
    .search-box .common-input {
        padding-right: 48px;
    }

    .banner {
        overflow: hidden;
    }
    
    @media (min-width: 992px) {
        .banner-thumb {
            left: 0;
            right: auto;
        }
    }

    .select-has-icon::before {
        left: 20px;
        right: auto;
    }

    .icon-right {
        margin-left: 0;
        margin-right: 10px;
        i::before {
            content: "\f060";
        }
    }
    
    .common-input {
        text-align: right;
    }

    .message-thumb {
        left: auto;
        right: 0;
    }
    
    .blog-item__date {
        left: 24px;
        right: auto !important;
    }
    .text-list__item .icon {
        margin-right: 0;
        margin-left: 8px;
    }

    .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        border-radius: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    
    .footer-menu__link::before {
        left: auto;
        right: 0;
    }
    
    a.footer-menu__link {
        padding-left: 0;
        padding-right: 28px;
    }

    .testimonial-thumb {
        right: auto;
        left: 0;
    }
    .slick-arrow {
        left: 0;
        right: clamp(1.5rem, -1.239rem + 5.705vw, 6.625rem);
    }
    /* ========================= Home One RTL Css End ============================== */


    /* ========================= Home Two RTL Css Start ============================== */
    .blog-item.blog-dark .blog-item__date {
        left: auto !important;
    }
    /* ========================= Home Two RTL Css End ============================== */

    /* ========================= Home Three RTL Css Start ============================== */
    .banner-three__shape {
        left: 0;
        right: auto;
        transform: translateY(-50%) scaleX(-1);
    }
    .about-three-thumb::before {
        transform: rotate(45deg);
        right: -40px;
        left: auto;
    }
    .testimonials-three__box::before {
        width: 100%;
    }

    .testimonials-three .slick-arrow {
        bottom: 0;
        left: 0;
    }

    .faq {
        overflow: hidden;
        &::before {
            left: auto;
            right: 0;
        }
        &-thumb {
            left: -5px;
            right: auto;
        }
    }
    .common-accordion .accordion-button::after {
        left: 26px;
        right: auto !important;
    }
    /* ========================= Home Three RTL Css End ============================== */

    /* ========================= Home Four RTL Css Start ============================== */
    .banner-four__inner {
        background-color: hsl(var(--white));
        mask-image: url(../images/shapes/banner-four-shape.png);
        mask-repeat: no-repeat;
        mask-size: contain;
        transform: scaleX(-1);
    }
    @media (max-width: 991px) {
        .banner-four__inner { 
            mask-image: none;
        }
    }
    .banner-four-content {
        padding-right: 0;
        transform: scaleX(-1);
        margin-left: 0 !important;
        margin-right: auto;
    }
    .arrow-style-two {
        .slick-arrow {
            right: auto;
            left: 0;
        }
        .slick-prev {
            left: 80px;
        }
    }
    .testimonial-item.style-four .testimonial-item__quote {
        right: auto;
        left: clamp(1.25rem, -0.271rem + 3.17vw, 2.5rem);
    }

    .working-process__shape {
        left: auto;
        right: 0;
    }

    @media (min-width: 1200px) {
        .pe-xl-5 {
            padding-left: 3rem !important;
            padding-right: 0rem !important;
        }
    }

    @media (min-width: 1200px) {
        .pe-lg-5 {
            padding-left: 3rem !important;
            padding-right: 0rem !important;
        }
    }
    .working-process-item__link {
        left: 0;
        right: auto;
        transform: translateY(-50%) scaleX(-1);
    }
    /* ========================= Home Four RTL Css End ============================== */

    /* ========================= Home Five RTL Css Start ============================== */
    .contact-number {
        padding-left: clamp(0.75rem, -0.771rem + 3.17vw, 2rem);
        margin-left: clamp(0.75rem, -0.771rem + 3.17vw, 2rem);
        border-left: 1px solid var(--border-color);
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
        + .offcanvas-btn {
            margin-left: 0;
        }
    }

    @media (min-width: 992px) {
        .banner-five__thumb {
            right: auto;
            left: 0;
            transform: scaleX(-1);
        }
    }
    /* ========================= Home Five RTL Css End ============================== */









    
}
