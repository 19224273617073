
/* ============= Header Start Here ======================= */
.header {
    background-color: hsl(var(--white));
    transition: .2s linear;
    @include md-screen {
        padding: 10px 0;
    }
    &.fixed-header {
        position: sticky;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        z-index: 9;
        backdrop-filter: blur(24px);
        animation: slideDown 0.35s ease-out;    
        background-color: hsl(var(--white)/.9) !important;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
        @include md-screen {
            top: -1px;
        }
    }
}
@keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
}

.offcanvas-btn {
    margin-right: clampCal(16, 36);
    svg, svg line, svg path {
        transition: .2s linear;
    }
    &:hover {
        svg {
            line, path {
                stroke: hsl(var(--main));
            }
        }
    }
}


.toggle-mobileMenu {
    line-height: 1;
    font-size: 36px;
    color: hsl(var(--heading-color));
}

.contact-number {
    padding-right: clampCal(12, 32);
    margin-right: clampCal(12, 32);
    border-right: 1px solid var(--border-color);
    @include msm-screen {
        @include font-14; 
    }
    @include xsm-screen {
        display: none !important;
    }
   
    &:hover {
        .text {
            @extend .text-gradient;             
        }
    }
    .icon {
        @include msm-screen {
            font-size: 15px !important;            
        }
    }
}


/* Header Menu and Submenu Css Start */
.nav-menu {
    gap: 24px;
    &__item {
        &.activePage {
            > a {
                color: hsl(var(--main));
                &::before {
                    color: hsl(var(--main));
                }
            }
        }
        &:hover {
            > a {
                color: hsl(var(--heading-color));
                @media (min-width: 992px) {
                    color: hsl(var(--main));
                }
            }
        }
    }
    &__link {
        color: hsl(var(--heading-color));
        font-weight: 500;
        @include font-16; 
        width: 100%;
        padding: 38px 0;
        width: 100%;
    }   
}

.has-submenu {
    position: relative;
    .nav-menu {
        &__link {
            padding-right: 16px;
        }
    }

    &.active {
        > a, > a::before{
            color: hsl(var(--main));
        }
        > a::before {
            transform: translateY(-50%) rotate(180deg) !important; 
        }
    }
    &:hover {
        .nav-submenu {
            visibility: visible;
            opacity: 1;
            margin-top: 0;
        }
        > a {
            &::before {
                @media (min-width: 992px) {
                    color: hsl(var(--main));
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }
    }
    > a {
        position: relative;
        &::before {
            position: absolute;
            content: "\f107";
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            @include font-10; 
            color: hsl(var(--heading-color)/.4);
            transition: .2s linear;
        }
    }
}

/* Submenu Start */
.nav-submenu {
    position: absolute;
    left: 0;
    top: 100%;
    width: max-content;
    background-color: hsl(var(--white));
    border-radius: 6px;
    min-width: 190px;
    overflow: hidden;
    padding: 8px;
    box-shadow: var(--box-shadow);
    visibility: hidden;
    opacity: 0;
    margin-top: 16px;
    transition: .2s linear;
    z-index: 99;
    @media (min-width: 992px) {
        max-height: 456px;
        overflow-y: auto;
    }
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #e4e4e4;
    }
    &::-webkit-scrollbar-thumb {
        background: #a2a2a2;
        border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #6d6d6d;
    }
    &__item {
        display: block;
        border-radius: 4px;
        transition: .2s linear;
        position: relative;
        &::before {
            position: absolute;
            content: "\f101";
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            @include font-10; 
            color: hsl(var(--heading-color));
            visibility: hidden;
            opacity: 0;
            transition: inherit;
        }
        &:hover {
            background-color: var(--gray-200);
            &::before {
                visibility: visible;
                opacity: 1;
            }
            a {
                margin-left: 10px;
                color: hsl(var(--heading-color));
            }
        }
    }
    &__link {
        color: hsl(var(--heading-color));
        font-weight: 500;
        @include font-16; 
        width: 100%;
        padding-right: 16px;
        width: 100%;
        display: block;
        padding: 8px 14px;
        border-radius: inherit;
        &::before {
            position: absolute;
        }
    }
}
/* Submenu End */
/* Header Menu and Submenu Css End */
/* ================================= Header Css End =========================== */
