/* ============================= Dashboard Widgets Css Start ========================== */
.dashboard-widget {
    background-color: hsl(var(--white));
    padding: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    @include sm-screen {
        padding: 16px;
    }
    &__icon {
        flex-shrink: 0;
        width: 38px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        color: hsl(var(--main));
        background-color: hsl(var(--main)/.2);
    }
    &__number {
        margin-bottom: 0;
    }
    &__text {
        font-size: 13px;
    }
}

.dashboard-widget-wrapper {
    div[class*="col"] {
        &:nth-child(2) .dashboard-widget {
            &__icon {
                color: #41B6FF;
                background-color: #41B6FF20;
            }
        }
        &:nth-child(3) .dashboard-widget {
            &__icon {
                color: #8b36f7;
                background-color: #8b36f720;
            }
        }
        &:nth-child(4) .dashboard-widget {
            &__icon {
                color: #FF4C80;
                background-color: #FF4C8020;
            }
        }
    }
}


/* ============================= Dashboard Widgets Css End ========================== */