/* ================================ Property Category Section Start ============================ */
.property-category {
    &-item {
        z-index: 1;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        &:hover {
            &::before {
                background: hsl(var(--black)/0.2);
            }
            .property-category-item {
                &__img {
                    transform: scale(1.1);
                }
            }
        }
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 1;
            background: hsl(var(--black)/0.6);
            transition: .2s linear;
        }
        &__img {
            transition: .2s linear;
        }
        &__content {
            position: absolute;
            left: 50%;
            bottom: 0px;
            transform: translateX(-50%);
            text-align: center;            
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            z-index: 2;
        }
        &__amount {
            position: absolute;
            right: 0;
            top: 0;
            background: var(--main-gradient);
            padding: 4px 16px;
            border-radius: 0 0 0 5px;
            font-size: 14px;
        }
    }
}
/* ================================ Property Category Section End ============================= */