/* ================================= Classes Css Start =========================== */
.container-two {
    @media (min-width: 1199px) {
        max-width: 1310px;
    }
}
/* Section Background */
.section-bg {
    background-color: hsl(var(--section-bg));
}
.section-bg-dark {
    background-color: var(--gray-700) !important;
}
.body-bg {
    background-color: hsl(var(--body-bg));
}
.bg-gray-100 {
    background-color: var(--gray-100) !important;
}

.max-w-unset {
    max-width: unset !important;
}
.min-w-maxContent {
    min-width: max-content;
}

.mt-minus-120{
    margin-top: -120px;
}

.mb-40 {
    margin-bottom: clampCal(24, 40) !important;
}

/* Bg Color */
.text-gradient {
    background: var(--main-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: background 0.3s ease-in-out;
}

.bg--gradient {
    background: var(--main-gradient) !important;
}
.bg {
    &-main {
        background-color: hsl(var(--main)) !important;
    }
    &-gray-100 {
        background-color: var(--gray-100) !important;
    }
    &-gray-700 {
        background-color: var(--gray-700) !important;
    }
    &-gray-800 {
        background-color: var(--gray-800) !important;
    }
    &-gray-900 {
        background-color: var(--gray-900) !important;
    }
}
/* Border Color */
.border {
    &-main {
        border-color: hsl(var(--main)) !important;
    }
    &-color {
        border-color: hsl(var(--border-color));
    }
}

.fw-500 {
    font-weight: 500 !important;
}
.left-auto {
    left: auto !important;
}
.font {
    &-body {
        font-family: var(--body-font);
    }
    &-heading {
        font-family: var(--heading-font);
    }
}

// z index
.z-index-1 {
    z-index: 1;
}


/* Text Color */
.text {
    &-heading {
        color: hsl(var(--heading-color)) !important;
    }
    &-body {
        color: hsl(var(--body-color)) !important;
    }
    &-main {
        color: hsl(var(--main)) !important;
    }
    &-gray-800 {
        color: var(--gray-800) !important;
    }
    &-poppins {
        font-family: var(--poppins-font);
    }
}

.pill {
    border-radius: 40px !important;
}

.gy-30 {
    --bs-gutter-y: 30px;
}

.top-120 {
    top: 120px;
}

.line-height-0 {
    line-height: 0;
}
.line-height-1 {
    line-height: 1;
}

/* Column Extra Small Screen */
.col-xs-6 {
    @media screen and (min-width: 425px) and (max-width: 575px) {
        width: 50%;
    }
}

/* Bg Image Css */
.background-img {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    width: 100%;
    height: 100%;
}

/* Hide Scroll bar Css For Custom Modal */
.scroll-hide {
    position: absolute;
    overflow-y: hidden;
    padding-right: 17px;
    top: 0;
    left: 0;
    width: 100%;
    @include md-screen {
        padding-right: 0;
    }
}
.scroll-hide-sm {
    position: absolute;
    overflow-y: hidden;
    top: 0;
    left: 0;
    width: calc(100% - 0px);
}

/* Overlay Start */
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    content: "";
    left: 0;
    top: 0;
    background-color: hsl(var(--black) / .6);
    z-index: 99;
    transition: .2s linear;
    visibility: hidden;
    opacity: 0;
    &.show-overlay {
        visibility: visible;
        opacity: 1;
    }
}

.side-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    content: "";
    left: 0;
    top: 0;
    background-color: hsl(var(--black) / .6);
    z-index: 99;
    transition: .2s linear;
    visibility: hidden;
    opacity: 0;
    &.show {
        visibility: visible;
        opacity: 1;
    }
}
/* Overlay End */

/* ================================= Classes Css End =========================== */