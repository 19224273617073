/* =========================== Banner Section Start Here ========================= */
.banner {
    position: relative;
    z-index: 1;
    padding: 100px 0 50px;
    overflow: hidden;
    @include md-screen {
        padding: 80px 0 80px;
    }
    @include sm-screen {
        padding: 60px 0 60px;
    }
    &-thumb {
        @media (min-width: 992px) {            
            position: absolute;
            height: 100%;
            max-width: 630px;
            max-width: 50%;
            top: 0;
            right: 0;
            z-index: -1;
        }
        @media (max-width: 991px) {
            margin-top: clampCal(24, 40);
            position: relative;
            max-width: 590px;
        }
    }
    .common-tab  {
        margin-top: clampCal(32, 90);
    }
}

.banner-content {
    max-width: 610px;
     &__subtitle {
        color: hsl(var(--heading-color));
        margin-bottom: 10px;
        letter-spacing: 0.11em;
        font-weight: 600;
        @include font-14; 
    }
    &__title {
        font-family: var(--poppins-font);
        margin-bottom: 20px;
        font-weight: 600;
        @include md-screen {
            margin-bottom: 12px;
        }
    }
    &__desc {
        max-width: 552px;
    }
    &__button {
        margin-top: 30px;
    }
}

.shape-element {
    position: absolute;
    left: 0;
    top: 70px;
    max-width: 15%;
    &.one {
        left: -30px;
        animation: rotation 8s linear infinite;
    }
    &.two {
        top: 134px;
        left: 150px;
        animation: rotationScale 8s linear infinite;
    }
    &.three {
        top: 174px;
        left: auto;
        right: 0;
        opacity: .92;
        animation: turnOver 8s linear infinite;
        perspective: 100px;
        perspective-origin: top;
    }
}

@keyframes rotation {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50%   {
        transform: translateY(80px) rotate(180deg);
    }
    100%   {
        transform: translateY(0px) rotate(360deg);
    }
}
@keyframes rotationScale {
    0% {
        transform: scale(1) rotate(0deg);
    }
  
    50%  {
        transform: scale(.8) rotate(180deg);
    }
    100%   {
        transform: scale(1) rotate(360deg);
    }
}
@keyframes turnOver {
    0% {
        transform: rotateX(0deg); 
    }
  
    50%  {
        transform: rotateX(180deg); 
    }
    100%   {
        transform: rotateX(0deg); 
    }
}
/* =========================== Banner Section End Here ========================= */